<template>
  <div>
    <div
      class=""
      v-if="isDateLoaded"
      style="height: 100%; width:100%;background: rgba(0, 0, 0, 0); z-index: 10; position: absolute; "
    >
      <div class="d-flex align-center justify-center" style="height: 100vh">
        <v-progress-circular indeterminate size="78" />
      </div>
    </div>
    <MainLayout>
      <template v-slot:navbar-title>
        {{ sourceObject.results ? sourceObject.results.phone_number : " " }}
      </template>
      <template v-slot:navbar-btn-wrap>
        <DeleteUserPopup
          v-if="$store.getters.getCurrentRole == 70"
          :name="deletePermission ? deletePermission.btnTitle : 'Пользователя'"
          :diller-organization-id="$route.params.dealerId"
          :object-id="$route.params.objectId"
          :group-id="$route.params.userGroupsId"
          :organization-id="$route.params.companyId"
          :kindergarten-id="$route.params.kindergartenId"
          :user-id="$route.params.id"
          :title="sourceObject.results ? sourceObject.results.phone_number : ''"
          :deletePermission="deletePermission"
          :description="
            deletePermission
              ? deletePermission.descriptions
              : 'Удалить пользователя из:'
          "
        />
        <UnBlockPopup
          name="Пользователя"
          v-if="
            !(sourceObject.results && sourceObject.results.is_active) &&
              $store.getters.getCurrentRole == 70
          "
          :title="sourceObject.results ? sourceObject.results.phone_number : ''"
          :un-block-function="unBanUser"
        ></UnBlockPopup>
        <BlockPopup
          name="Пользователя"
          :title="sourceObject.results ? sourceObject.results.phone_number : ''"
          v-if="
            $store.getters.getCurrentRole == 70 &&
              sourceObject.results &&
              sourceObject.results.is_active
          "
          :block-function="banUser"
        />
        <DeletePopupAdmin
          title="Аккаунт"
          description="Внимание!

Произойдет полное удаление пользователя из системы и всех с ним связанных данных. Удалить?"
          :dialog="dialog"
          @closeDeletePopup="closeDeletePopup"
          :delete-function="deleteFunction"
        />
        <router-link
          v-if="urlQuery && urlQuery.id"
          :to="urlEdit + urlQuery.id"
          class="btn btn-edit btn_color_grey"
          >редактировать</router-link
        >
      </template>
      <SlideLayout :tabs="slideTabs">
        <template v-slot:info>
          <div class="keep__title">
            <p class="keep__text">Общая информация</p>
          </div>
          <BasicTableWithoutHeader
            v-if="sourceObject.results"
            :table-data="getDataRequisites"
          >
            <template #item.3.value="{item}">
              <p
                :style="
                  item.value
                    ? 'color: #95C23D !important'
                    : 'color: #EB5C6D !important'
                "
              >
                {{ item.value }}
              </p>
            </template>
            <template #item.4.value="{item}">
              <p
                :style="
                  item.value == 'Не заблокирован'
                    ? 'color: #767d92 !important'
                    : 'color: #EB5C6D !important'
                "
              >
                {{ item.value }}
              </p>
            </template>
            <template v-if="currentRole == 31" #item.1.value="{}">
              <div class="d-flex ">
                <ValidationInputField
                  class="mr-4 d-flex "
                  mask="###.#####"
                  :disabled="
                    input_disabled ||
                      (code && code.length == 9 && code_isEdit == false)
                  "
                  v-model="code"
                />

                <div class="d-flex align-center">
                  <div
                    style=" border-radius: 50px; color: #ffffff; padding:5px 15px;"
                    :style="{
                      background:
                        user_payments === 'paid'
                          ? '#95C23D'
                          : user_payments === 'not-paid'
                          ? '#EB5C6D'
                          : '#6D778E'
                    }"
                  >
                    <p class="text-align_center">
                      {{
                        user_payments === "paid"
                          ? "Оплачена"
                          : user_payments === "not-paid"
                          ? "Не оплачена"
                          : "Ожидает ввода карты"
                      }}
                    </p>
                  </div>
                </div>
                <div class="ml-auto">
                  <template v-if="currentRole == 31">
                    <v-btn
                      type="submit"
                      @click="editParent"
                      class="btn btn-green "
                      :disabled="
                        input_disabled ||
                          (code && code.length == 9 && code_isEdit == false)
                      "
                    >
                      Сохранить
                    </v-btn>
                  </template>
                </div>
              </div>
            </template>
            <template
              v-if="
                !showRestore &&
                  !showConfirmation &&
                  currentUser &&
                  !restoreUser &&
                  currentRole == 70
              "
              #item.7.value="{}"
            >
              <v-btn
                type="submit"
                @click="dialogShow"
                color="#EB5C6D"
                class="btn btn-delete btn_color_red"
              >
                Удалить
              </v-btn>
            </template>
          </BasicTableWithoutHeader>
        </template>

        <template v-slot:objects v-if="$store.getters.getCurrentRole !== 21">
          <UsersObject />
        </template>
        <template v-slot:payments v-if="$store.getters.getCurrentRole !== 21">
          <UsersPayments />
        </template>
        <template v-slot:pass_log v-if="$store.getters.getCurrentRole !== 21">
          <UserPassageLog />
        </template>
        <template v-slot:access v-if="$store.getters.getCurrentRole !== 21">
          <UsersAccess />
        </template>
      </SlideLayout>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import * as query from "@/helpers/query";
import UsersObject from "@/components/info/users/UsersObject";
import UsersPayments from "@/components/info/users/UsersPayments";
import UsersAccess from "@/components/info/users/UsersAccess";
import BlockPopup from "@/components/popup/BlockPopup";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import UserPassageLog from "@/components/info/users/UserPassageLog";
import slideTabsUsers from "@/helpers/slideTabs/users";
import phoneFilter from "@/helpers/filters/phoneFilter";
import DeleteUserPopup from "@/components/popup/DeleteUserPopup";
import DeletePopupAdmin from "@/components/popup/DeletePopupCommon";

import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import { getIdentificatorUserRole } from "../../helpers/Constants/roles";
import moment from "moment";
import router from "@/router";

export default {
  name: "UsersInfosPages",
  components: {
    ValidationInputField,
    DeleteUserPopup,
    UserPassageLog,
    UnBlockPopup,
    BasicTableWithoutHeader,
    BlockPopup,
    SlideLayout,
    MainLayout,
    UsersObject,
    UsersPayments,
    UsersAccess,
    DeletePopupAdmin
  },
  props: {
    deletePermission: {
      // передаем из роута разрешения на удаления from : [groups, object, kindergarten, organization, diller]
      type: Object
    }
  },
  data() {
    return {
      type: "users",
      queries: query.organizations,
      showConfirmation: false,
      codeDel: null,
      showRestore: false,
      dialog: false,
      infoTabs: [
        {
          text: "Общая информация",
          value: "recv"
        }
      ],
      headers: [
        {
          value: "text",
          text: "text"
        },
        {
          value: "value",
          text: "value"
        }
      ]
    };
  },
  methods: {
    dialogShow() {
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },

    deleteFunction() {
      this.$store
        .dispatch("deleteProfileForce", { id: this.sourceObject.results.id })

        .then(() => {
          this.$store
            .dispatch(
              "successNotification",
              actionMessage.remove(successMessageDictionary.profile)
            )
            .finally(() => router.go(-1));

          this.isDateLoaded = true;
          this.showConfirmation = true;
        });
    },

    restoreFunction() {
      this.isDateLoaded = true;
      this.$store
        .dispatch("restoreProfile", { id: this.sourceObject.results.id })
        .then(() => {
          this.showRestore = false;
          this.showConfirmation = false;

          this.$store.dispatch(
            "successNotification",
            actionMessage.restore(successMessageDictionary.profile)
          );
        })
        .then(() => {
          this.$store.dispatch("fetchUserInfo");
        });
    },
    editParent() {
      this.loading = true;
      this.$store
        .dispatch("editParent", {
          id: this.$route.params.id,
          kinderId: getIdentificatorUserRole()
        })
        .then(() => {
          this.$store.dispatch("fetchUserInfo");

          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.parent)
          );
        })
        .finally(() => (this.loading = false));
    },

    deleteUser() {
      this.$store.dispatch("deleteUser", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.remove(successMessageDictionary.user)
        );
      });
    },
    banUser() {
      this.$store.dispatch("banUser", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.ban(successMessageDictionary.user)
        );
      });
    },
    unBanUser() {
      this.$store.dispatch("unBanUser", { id: this.urlQuery.id }).then(() => {
        this.$store.dispatch(
          "successNotification",
          actionMessage.unBan(successMessageDictionary.user)
        );
      });
    }
  },

  computed: {
    input_disabled: {
      get() {
        return this.$store.getters.getEditParent.input_disabled.value;
      }
    },
    code_isEdit: {
      get() {
        return this.$store.getters.getEditParent.code.isEdit;
      }
    },
    code: {
      get() {
        return this.$store.getters.getEditParent?.code.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "code",
          value: newValue
        });
      }
    },
    user_payments: {
      get() {
        return this.$store.getters.getEditParent?.user_payments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "user_payments",
          value: newValue
        });
      }
    },

    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    slideTabs() {
      return slideTabsUsers.filter(el =>
        el.role.includes(this.$store.getters.getCurrentRole)
      );
    },
    sourceObject() {
      return this.$store.getters.getUserInfo;
    },
    restoreUser() {
      return this.$store.getters.getUserInfo.results.marked_to_clean_at;
    },
    urlEdit() {
      return this.currentRole == 31 && localStorage.getItem("KindergartenId")
        ? "/editParent/"
        : this.currentRole != 31 && localStorage.getItem("ObjectId")
        ? "/usersObjectsEdit/"
        : this.currentRole == 70 ||
          this.currentRole == 60 ||
          this.currentRole == 30
        ? "/usersGeneralEdit/"
        : "/usersEdit/";
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    getDataRequisites() {
      if (this.currentRole == 31) {
        return [
          {
            text: "Телефон",
            value: phoneFilter.execute(
              this?.sourceObject?.results?.phone_number
            )
          },

          {
            text: "Статус карты",
            value: this.sourceObject.results?.cards[0]?.status_display
              ? this.sourceObject.results?.cards[0]?.status_display
              : " "
          },

          {
            text: "Регистрация",
            value: this.sourceObject.results?.registered_at
              ? moment(this.sourceObject.results.registered_at * 1000).format(
                  "DD.MM.YYYY H:mm"
                )
              : "Нет"
          },
          {
            text: "Статус",
            value:
              dictionariesHelper.accountsStatus.userStatus[
                this.sourceObject.results.is_active
              ]
          },
          {
            text: "Дата добавления в систему",
            value: moment(this.sourceObject.results.created_at * 1000).format(
              "DD.MM.YYYY H:mm"
            )
          },
          // {
          //   text: "Дата регистрации пользователя",
          //   value: this.sourceObject.results?.registered_at
          //     ? moment(this.sourceObject.results.registered_at * 1000).format(
          //         "H:mm DD.MM.YYYY"
          //       )
          //     : "-"
          // },
          {
            text: "Возможность активации тестового периода",
            value:
              this.sourceObject.results.free_trial === true
                ? "Доступен"
                : "Использован"
          },
          {
            text: "ID",
            value: this.sourceObject.results.id
          }
        ];
      }
      if (this.currentRole == 70) {
        return [
          {
            text: "ФИО",
            value: ` ${this.sourceObject.results?.last_name || "-"} ${this
              .sourceObject.results?.first_name || ""} ${this.sourceObject
              .results?.middle_name || ""}`
          },
          {
            text: "Телефон",
            value: phoneFilter.execute(
              this?.sourceObject?.results?.phone_number
            )
          },

          {
            text: "Регистрация",
            value: this.sourceObject.results?.registered_at
              ? moment(this.sourceObject.results.registered_at * 1000).format(
                  "DD.MM.YYYY H:mm"
                )
              : "Нет"
          },
          {
            text: "Статус",
            value:
              dictionariesHelper.accountsStatus.userStatus[
                this.sourceObject.results.is_active
              ]
          },
          {
            text: "Дата добавления в систему",
            value: moment(this.sourceObject.results.created_at * 1000).format(
              "DD.MM.YYYY H:mm "
            )
          },
          // {
          //   text: "Дата регистрации пользователя",
          //   value: this.sourceObject.results?.registered_at
          //     ? moment(this.sourceObject.results.registered_at * 1000).format(
          //         "DD.MM.YYYY H:mm "
          //       )
          //     : "-"
          // },
          {
            text: "Возможность активации тестового периода",
            value:
              this.sourceObject.results.free_trial === true
                ? "Доступен"
                : "Использован"
          },
          {
            text: "ID",
            value: this.sourceObject.results.id
          },
          {
            text: "Удалить профиль",
            value: this.sourceObject.id
          }
        ];
      } else {
        return [
          // {
          //   text: "ФИО",
          //   value: ` ${this.sourceObject.results?.last_name || ""} ${this
          //       .sourceObject.results?.first_name || ""} ${this.sourceObject
          //       .results?.middle_name || ""}`
          // },
          {
            text: "Телефон",
            value: phoneFilter.execute(
              this?.sourceObject?.results?.phone_number
            )
          },

          {
            text: "Регистрация",
            value: this.sourceObject.results?.registered_at
              ? moment(this.sourceObject.results.registered_at * 1000).format(
                  "DD.MM.YYYY H:mm"
                )
              : "Нет"
          },
          {
            text: "Статус",
            value:
              dictionariesHelper.accountsStatus.userStatus[
                this.sourceObject.results.is_active
              ]
          },
          {
            text: "Дата добавления в систему",
            value: moment(this.sourceObject.results.created_at * 1000).format(
              "DD.MM.YYYY H:mm "
            )
          },
          // {
          //   text: "Дата регистрации пользователя",
          //   value: this.sourceObject.results?.registered_at
          //     ? moment(this.sourceObject.results.registered_at * 1000).format(
          //         "DD.MM.YYYY H:mm"
          //       )
          //     : "-"
          // },
          {
            text: "Возможность активации тестового периода",
            value:
              this.sourceObject.results.free_trial === true
                ? "Доступен"
                : "Использован"
          },
          {
            text: "ID",
            value: this.sourceObject.results.id
          }
        ];
      }
    }
  },

  created() {
    if (this.currentRole == 31 || this.currentRole == 21) {
      this.$store.dispatch("prepareEditParent", {
        id: this.$route.params.id,
        kinderId: this.$store.state.userInterface.currentOrganization
      });
    }

    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    if (localStorage.getItem("ObjectId")) {
      this.$store.dispatch("fetchUserObjectInfo").finally(() => {
        this.isDateLoaded = false;
      });
    } else {
      this.$store.dispatch("fetchUserInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchUserInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearUsersPage").then(() => next());
  }
};
</script>

<style scoped></style>
